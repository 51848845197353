import Link from 'next/link';

interface PropsIC {
  data: {
    internalTitle: string;
    buttonText: string;
    buttonUrl: string;
    document: {
      url: string;
    };
  };
}

const NavigationButton = ({ data }: PropsIC) => {
  const { internalTitle, buttonText, buttonUrl, document } = data || {};

  return (
    <div>
      {internalTitle && <h3 className="mb-7">{internalTitle}</h3>}
      {buttonUrl && (
        <Link
          href={document?.url ? document.url : buttonUrl}
          target={`${document?.url ? '_blank' : ''}`}
          className="ml-3 green-button group"
        >
          <p className="group-hover:text-brand-green">{buttonText}</p>
        </Link>
      )}
    </div>
  );
};

export default NavigationButton;
