import ContentfulImage from '@/components/contentful-image';
import AddPlusIcon from '@/public/icons/add-plus-icon.svg';
import RemoveMinusIcon from '@/public/icons/remove-minus-icon.svg';
import { useState } from 'react';
import RichText from '../rich-text';
import { useOSContext } from '@/context/OSContext';

const Accordion = ({ item }: any) => {
  const { title, description } = item;
  const [isDescriptionVisible, setDescriptionVisible] = useState(false);
  const osContext = useOSContext()

  const toggleDescriptionVisibility = () => {
    setDescriptionVisible((prevState) => !prevState);
  };

  return (
    <div>
      <div
        className={`flex gap-5 justify-between cursor-pointer ${isDescriptionVisible ? '0' : 'pb-8'}`}
        onClick={toggleDescriptionVisibility}
      >
        <h5 className="m-0 pr-5">{title}</h5>
        <div
          className={`min-w-[24px] ${
            osContext?.os === 'Windows' && 'mt-[6px]'
          }`}
        >
          <ContentfulImage
            src={isDescriptionVisible ? RemoveMinusIcon : AddPlusIcon}
            width={24}
            height={24}
            className=""
          />
        </div>
      </div>
      {isDescriptionVisible && (
        <div className="py-4">
          <RichText data={description} />
        </div>
      )}
    </div>
  );
};

export default Accordion;
